<template>
  <FormGroup :v="v" :forId="id" :title="label" :titleSuffix="labelSuffix" :tooltipText="tooltipText" :required="isRequired" :class="{ 'has-icon': icon }" :help="help" :hideError="hideError">
    <CombinedInput v-if="$slots.prepend || $slots.append" noWrap noMargin>
      <template #prepend>
        <slot v-if="$slots.prepend" name="prepend"/>
      </template>
      <the-mask v-if="mask" :size="size" :mask="mask" :tokens="tokens" :masked="true" :class="className" :id="id" :type="type" :value="value ? value : ''" v-on="computedListeners" :placeholder="placeholder" :min="min" :max="max" :autocomplete="autocomplete" :disabled="disabled" :autofocus="autofocus" />
      <input v-else :size="size" :class="className" :id="id" :type="type" :value="value ? value : ''" v-on="computedListeners" :placeholder="placeholder || (this.max && this.max !== Infinity && `Maximum ${this.max}`) || (this.max === 0 && 'Maximum bereikt')" :min="min" :max="max" :autocomplete="autocomplete" :disabled="disabled" :autofocus="autofocus">
      <template #append>
        <slot v-if="$slots.append" name="append"/>
      </template>
    </CombinedInput>
    <div v-else>
      <the-mask v-if="mask" :size="size" :mask="mask" :tokens="tokens" :masked="true" :class="className" :id="id" :type="type" :value="value ? value : ''" v-on="computedListeners" :placeholder="placeholder" :min="min" :max="max" :autocomplete="autocomplete" :disabled="disabled" :autofocus="autofocus" />
      <input v-else :size="size" :class="className" :id="id" :type="type" :value="value ? value : ''" v-on="computedListeners" :placeholder="placeholder || (this.max && this.max !== Infinity && `Maximum ${this.max}`) || (this.max === 0 && 'Maximum bereikt')" :min="min" :max="max" :autocomplete="autocomplete" :disabled="disabled" :autofocus="autofocus">
      <Icon v-if="icon" :name="icon" @click="$emit('click')" />
      <a href="#" v-if="suggestion" @click.prevent="$emit('change', suggestion)">Bedoelde je <strong>{{ suggestion }}?</strong></a>
    </div>
  </FormGroup>
</template>

<script>
import FormGroup from './FormGroup'
import Icon from './Icon'
import { TheMask } from 'vue-the-mask'
import CombinedInput from './CombinedInput.vue'

export default                       {
  name: 'Input',
  components: {
    Icon,
    FormGroup,
    TheMask,
    CombinedInput
  },
  props: {
    id: String,
    type: {
      type: String,
      default: 'text'
    },
    value: [String, Number, Boolean],
    label: String,
    labelSuffix: String,
    tooltipText: String,
    placeholder: String,
    full: Boolean,
    min: [Number, String],
    max: [Number, String],
    required: [Boolean, Number, String],
    v: Object,
    autocomplete: String,
    disabled: Boolean,
    icon: String,
    autofocus: Boolean,
    help: String,
    mask: [Array, Boolean],
    tokens: {
      type: Object,
      default () {
        return {
          P: {
            pattern: /[1-9]/
          },
          '#': {
            pattern: /\d/
          }
        }
      }
    },
    size: {
      type: [Number, Boolean],
      default: false
    },
    smallPadding: Boolean,
    center: Boolean,
    hideError: Boolean,
    suggestion: [String, Boolean],
    error: [String, Boolean]
  },
  mounted () {
    if (String(this.value).length && (this.min || this.max)) {
      if (this.max) this.$emit('input', Math.min(this.max, parseFloat(this.value)))
      if (this.min) this.$emit('input', Math.max(this.min, parseFloat(this.value)))
    }
  },
  methods: {
    updateValue (e) {
      if (e instanceof Event && e.target) {
        if (this.max) e.target.value = Math.min(this.max, parseFloat(e.target.value))
        if (this.min) e.target.value = Math.max(this.min, parseFloat(e.target.value))
        this.$emit('input', e.target.value)
      } else {
        this.$emit('input', e)
      }
    },
    handleBlur (e) {
      if (this.v) {
        this.v.$touch()
      }
      this.$emit('blur', e)
    }
  },
  computed: {
    className () {
      const classes = []
      if (this.full) {
        classes.push('full')
      }
      if (this.v && this.v.$error) {
        classes.push('error')
      }
      if (this.smallPadding) classes.push('small-padding')
      if (this.center) classes.push('center')
      return classes
    },
    isRequired () {
      let required = this.required
      if (typeof required === 'string' && !isNaN(required)) {
        required = Number(required)
      }
      return required || (this.v && 'required' in this.v)
    },
    computedListeners () {
      return {
        ...this.$listeners,
        input: this.updateValue,
        blur: this.handleBlur,
        change: e => {
          if (this.max) e.target.value = Math.min(this.max, parseFloat(e.target.value))
          if (this.min) e.target.value = Math.max(this.min, parseFloat(e.target.value))
          this.$emit('change', e.target.value)
        }
      }
    },
    computedIconListeners () {
      return {
        ...this.$listeners
      }
    }
  }
}
</script>

<style lang="scss">
label {
  display: block;
  margin-bottom: 0.3rem !important;
}
label .required {
  color: var(--danger-color);
  margin-left: 0.35rem;
}
input {
  border: 1px solid var(--gray-darker);
  padding: 0.6rem 1rem;
  border-radius: var(--border-radius);
  outline: 0;
  width: 100%;
  font-size: 1rem;

  &.small-padding {
    padding: 0.6rem 0.5rem;
  }

  &.center {
    text-align: center;
  }

  &:focus {
    border-color: var(--primary-color);
  }

  &.full {
    width: 100%;
  }

  &.error {
    border-color: var(--danger-color);
  }

  &:disabled {
    cursor: not-allowed;
  }
}
div.has-icon {
  display: flex;

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  i {
    border: 1px solid var(--gray-darker);
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    border-left: 0;
    display: flex;
    align-items: center;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
}
</style>
