<template>
  <div ref="dropzone" class="dropzone" :class="{ 'has-error': v && v.$error }"></div>
</template>

<script>
import Dropzone from 'dropzone'
Dropzone.autoDiscover = false
export default {
  name: 'Dropzone',
  props: {
    options: Object,
    v: Object
  },
  mounted () {
    this.dropzone = new Dropzone(this.$refs.dropzone, this.dropzoneOptions)
    this.dropzone.on('complete', file => {
      this.handleSuccessUpload(file)
    })
    if (this.dropzoneOptions && this.dropzoneOptions.autoProcessQueue === false) {
      this.dropzone.on('addedfile', (file) => {
        this.$emit('add', file)
      })
    }
    this.dropzone.on('removedfile', (file) => {
      this.$emit('remove', file.path)
      if (this.dropzone.options.maxFiles !== 'undefined' && file.manuallyAdded) {
        this.existingFiles--
        this.dropzone.options.maxFiles = this.dropzoneOptions.maxFiles - this.existingFiles
      }
    })
  },
  data () {
    return {
      dropzone: false,
      existingFiles: 0
    }
  },
  computed: {
    id () {
      return this._uid
    },
    dropzoneOptions () {
      return {
        url: this.$apiUrl + '/' + this.options.endpoint,
        thumbnailWidth: 150,
        maxFilesize: 8,
        maxFiles: this.options.maxFiles,
        autoProcessQueue: this.options.autoProcessQueue !== false,
        acceptedFiles: this.parseAcceptedFiles() || 'image/*,application/pdf,.docx,.xlsx',
        parallelUploads: this.options.parallelUploads || 2,
        params: {
          auth: this.$store.getters['user/auth'],
          ...this.options.params
        },
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null
        },
        addRemoveLinks: true,
        dictDefaultMessage: this.$t('Slepen en neerzetten of klik om een bestand toe te voegen'),
        dictMaxFilesExceeded: this.$t('Je kan maximum {{maxFiles}} bestand(en) opladen.'),
        dictRemoveFile: this.$t('Verwijderen')
      }
    }
  },
  methods: {
    parseAcceptedFiles () {
      const rules = []
      if (this.options.acceptedFiles && this.options.acceptedFiles.includes('image')) rules.push('image/*')
      if (this.options.acceptedFiles && this.options.acceptedFiles.includes('file')) {
        rules.push('application/pdf')
        rules.push('.docx')
        rules.push('.xlsx')
      }
      return rules.join(',')
    },
    handleSuccessUpload (file) {
      if (!file.xhr || file.status === 'error') return
      const data = JSON.parse(file.xhr.response)
      this.$emit('add', data.file)
    },
    addFiles (files) {
      if (this.dropzone) {
        for (const file of files) {
          if (file.length === 0) continue
          const url = `${this.$apiUrl}/${file}`

          const mockFile = { name: url, size: 1024 }

          this.dropzone.options.addedfile.call(this.dropzone, mockFile)
          this.dropzone.options.thumbnail.call(this.dropzone, mockFile, url)
          this.existingFiles++
        }
        if (this.dropzone.options.maxFiles) {
          this.dropzone.options.maxFiles = this.dropzoneOptions.maxFiles - this.existingFiles
        }
      }
    },
    processQueue (data) {
      this.dropzone.options.params.submission = data
      this.dropzone.processQueue()
    }
  }
}
</script>
<style lang="scss" scoped>
.has-error {
  border-color: var(--danger-color);
}
</style>
