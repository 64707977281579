<template>
  <div v-if="!loading" class="course-form-preview">
    <Collapse :labelClosed="'Basisgegevens verbergen'" :labelOpen="'Basisgegevens tonen'">
      <Input :label="'Voornaam'" placeholder="Voornaam van de deelnemer" />
      <Input :label="'Naam'" placeholder="Naam van de deelnemer" />
      <Input :label="'Emailadres'" placeholder="emailadres gebruikersprofiel" />
      <Input :label="'Secundaire email adressen'" />
      <Phone :label="'Telefoonnummer'" />
      <Datepicker :label="'Geboortedatum'" placeholder="dd/mm/jjjj" />
    </Collapse>
    <Grid v-if="nationalityField">
      <Field v-if="nationalityField" :field="nationalityField" v-model="nationalityField.answer" ref="field" />
    </Grid>
    <Grid v-if="parseInt($route.query.allowTaxCertificate) || parseInt($route.query.nationalInsuranceNumber)" :columns="{ tablet: 2 }">
      <div>
        <Input :tooltipText="'Noodzakelijk voor het opmaken van fiscale attesten'" style="margin-bottom: 0;" :label="$t('Rijksregisternummer ')" :labelSuffix="$t(registrationFormData[0].user.firstname + ' ' + registrationFormData[0].user.lastname)" :placeholder="'xx.xx.xx-xxx.xx'" />
        <FormError v-if="userErrorText">{{ userErrorText }}</FormError>
      </div>
      <div v-if="!parseInt($route.query.nationalInsuranceNumber) || (parseInt($route.query.nationalInsuranceNumber) && parseInt($route.query.allowTaxCertificate))">
        <Datepicker :label="$t('Geboortedatum ')" :labelSuffix="$t(registrationFormData[0].user.parentName) || 'Voogd'" />
        <Input :tooltipText="'Noodzakelijk voor het opmaken van fiscale attesten'" :label="$t('Rijksregisternummer ')" :labelSuffix="$t('Voogd')" :placeholder="'xx.xx.xx-xxx.xx'" />
        <FormError v-if="parentErrorText">{{ parentErrorText }}</FormError>
      </div>
    </Grid>
    <Address v-if="parseInt($route.query.allowTaxCertificate)" label="Adres" :labelSuffix="$t('Voogd')" />
    <RegistrationForm v-if="registrationFormData" :user="registrationFormData[0].user" v-model="registrationFormData[0].registrationFormData" specificInline no-button hideNationalityField />
    <Checkbox>
      Ik ga akkoord met <a href="#">de privacyverklaring en de voorwaarden van de club</a>.
    </Checkbox>
  </div>
  <div v-else style="text-align: center; padding: 2rem;">
    <Icon name="spinner" size="3" animation="spin" />
  </div>
</template>

<script>
import api from '../../api'
import RegistrationForm from '../../components/Form/RegistrationForm'
import Grid from '../../components/Core/Grid.vue'
import FormError from '../../components/Core/FormError.vue'
import Datepicker from '../../components/Core/Datepicker.vue'
import Input from '../../components/Core/Input.vue'
import Icon from '../../components/Core/Icon.vue'
import Collapse from '../../components/Core/Collapse.vue'
import Checkbox from '../../components/Core/Checkbox.vue'
import Phone from '../../components/Core/Phone.vue'
import Address from '../../components/Core/Address.vue'
import Field from "../../components/Form/Field.vue";

export default {
  name: 'CourseFormPreview',
  components: {
    Field,
    RegistrationForm,
    Grid,
    FormError,
    Datepicker,
    Input,
    Icon,
    Collapse,
    Checkbox,
    Phone,
    Address
  },
  data () {
    return {
      registrationFormData: false,
      loading: true,
      sex: ''
    }
  },
  mounted () {
    this.fetchData()
  },
  computed: {
    isBelgianNationality () {
      if (!this.nationalityField) return false

      return this.nationalityField.answer && this.nationalityField.answer === 'Belgisch'
    },
    nationalityField () {
      if (!this.registrationFormData || !this.registrationFormData.length) return false
      if (!this.registrationFormData[0].registrationFormData || !this.registrationFormData[0].registrationFormData.length) return false
      if (!this.registrationFormData[0].registrationFormData[0].fields || !this.registrationFormData[0].registrationFormData[0].fields.length) return false

      let nationalityField = false
      this.registrationFormData[0].registrationFormData.forEach((section) => {
        let nationalityFieldExists = section.fields.find((field) => field.default_key === 'nationality')
        if (nationalityFieldExists) nationalityField = nationalityFieldExists
      })
      return nationalityField
    },
  },
  methods: {
    async fetchData () {
      try {
        this.loading = true
        const response = await api.post('get_combined_course_registration_form', {
          tariffs: [this.$route.query.tariff],
          passes: this.$store.getters['cart/passesData'],
          uid: this.$route.query.uid,
          coupleCourse: this.$store.getters['cart/coupleCourseData'],
          course: this.$route.query.nid,
          club: this.$route.query.club,
          sections: this.$store.getters['cart/sections'],
          formPreview: this.$route.query.questions.split(','),
          dsvPreview: this.$route.query.dsv,
          allowTaxCertificatePreview: this.$route.query.allowTaxCertificate
        })

        this.registrationFormData = response.data.result.map(section => {
          section.fields = section.fields.map(field => {
            if (!field.answer) {
              field.answer = field.last_answer
            }
            return field
          }).filter((field) => field.default_key !== 'sex')
          return section
        })

        this.registrationFormData = [
          {
            registrationFormData: this.registrationFormData,
            user: response.data.users[0]
          }
        ]
        this.loading = false
      } catch (error) {
        return this.$store.commit('notice/addNotices', {
          title: 'Er is iets misgegaan',
          message: error.message,
          type: 'error'
        })
      }
    }
  }
}
</script>
