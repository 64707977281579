import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import media from './plugins/media'
import format from './plugins/format'
import i18n from './i18n'
import Vuelidate from 'vuelidate'
import moment from 'moment'
import 'moment/locale/nl'
import VueMoment from 'vue-moment'
import VueMeta from 'vue-meta'
import VCalendar from 'v-calendar'
import axios from 'axios'
import DatePicker from 'v-calendar/src/components/DatePicker.vue'

Vue.config.productionTip = false
Vue.use(media)
Vue.use(format)
Vue.use(Vuelidate)
Vue.use(VueMoment, {
  moment
})
Vue.use(VueMeta)
Vue.use(VCalendar)

// Vue.component('calendar', Calendar)
Vue.component('v-date-picker', DatePicker)

if (process.env.VUE_APP_API_URL) {
  axios.defaults.baseURL = Vue.prototype.$apiUrl = process.env.VUE_APP_API_URL
} else if (process.env.VUE_APP_BRANCH === 'master') {
  axios.defaults.baseURL = Vue.prototype.$apiUrl = 'https://api.ledenbeheer.be'
} else if (process.env.VUE_APP_BRANCH === 'development') {
  axios.defaults.baseURL = Vue.prototype.$apiUrl = 'https://staging.api.ledenbeheer.be'
} else {
  const slugify = (str) => {
    str = str.replace(/^\s+|\s+$/g, '') // trim leading/trailing white space
    str = str.toLowerCase() // convert string to lowercase
    str = str.replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
      .replace(/\s+/g, '-') // replace spaces with hyphens
      .replace(/-+/g, '-') // remove consecutive hyphens
    return str
  }
  axios.defaults.baseURL = Vue.prototype.$apiUrl = 'https://' + slugify(process.env.VUE_APP_BRANCH) + '.api.ledenbeheer.be'
}

Vue.config.errorHandler = (err, vm, info) => {
  if (err.name === 'UserError') {
    store.commit('notice/addNotices', {
      title: err.title,
      message: err.message,
      type: 'error'
    })
  } else if (!err.message.includes('Avoided redundant navigation to current location')) {
    throw err
  }
}

window.addEventListener('unhandledrejection', (event) => {
  if (process.env.NODE_ENV === 'production') {
    event.preventDefault()
  }
})


const initApp = (shadowRoot = null ) => {
  const app = new Vue({
    router,
    shadowRoot,
    store,
    i18n,
    render: h => h(App)
  })
  return app
}
export default initApp
