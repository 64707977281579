import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import api from '../api'
import { read, remove } from '../utils/cookies'
import '../views/Preview/CourseFormPreview'

const Error404 = () => import('../views/Error/Error404')
const Login = () => import('../views/Login')
const Home = () => import('../views/Home')
const Overview = () => import('../views/Overview/Index')
const Styleguide = () => import('../views/Styleguide')
const Dashboard = () => import('../views/Dashboard/Index')
const Calendar = () => import('../views/Calendar/Index')
const Certificate = () => import('../views/Certificate/Index')
const Invoice = () => import('../views/Invoice/Index')
const Communication = () => import('../views/Communication/Index')
const CommunicationDetail = () => import('../views/Communication/Detail')
const Profile = () => import('../views/Profile/Index')
const AddFamilyMember = () => import('../views/Profile/AddFamilyMember')
const Teacher = () => import('../views/Teacher/Index')
const Confirmation = () => import('../views/Cart/Confirmation')
const DanssportVlaanderen = () => import('../views/DanssportVlaanderen/Index')
const DanssportVlaanderenCard = () => import('../views/DanssportVlaanderen/Card')
const MembershipCard = () => import('../views/MembershipCard/Index')
const Video = () => import('../views/Video/Index')
const VideoCourse = () => import('../views/Video/Course')
const VideoDetail = () => import('../views/Video/Detail')
const Webshop = () => import('../views/Webshop/Index')
const WebshopDetail = () => import('../views/Webshop/Detail')
const WebshopCategory = () => import('../views/Webshop/Category')
const Course = () => import('../views/Course/Index')
const FormList = () => import('../views/Form/Index')
const FormDetail = () => import('../views/Form/Detail')
const Invite = () => import('../views/Invite/Index')
const Pass = () => import('../views/Pass/Index')
const Ticket = () => import('../views/Ticket/Index')
const Cart = () => import('../views/Cart/Index')
const PassReload = () => import('../views/Pass/PassReload')
const PassLog = () => import('../views/Pass/PassLog')
const PassReserve = () => import('../views/Pass/Reserve')
const CourseFormPreview = () => import('../views/Preview/CourseFormPreview')

const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.replace = function push (location) {
  return originalReplace.call(this, location).catch(() => {})
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/404',
    name: '404',
    component: Error404
  },
  {
    path: '/styleguide',
    name: 'Styleguide',
    component: Styleguide
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/attesten',
    name: 'Certificate',
    component: Certificate,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/kostennota-s',
    name: 'Invoice',
    component: Invoice,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/communication',
    name: 'Communication',
    component: Communication,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/communication/:club/:url',
    name: 'CommunicationDetail',
    component: CommunicationDetail,
    meta: {
      requiresAuth: true,
      requiresClub: true
    }
  },
  {
    path: '/profiel',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true,
      requiresSelectedUser: true,
      defaultParentUser: true
    }
  },
  {
    path: '/docent',
    name: 'Teacher',
    component: Teacher,
    meta: {
      requiresAuth: true,
      requiresSelectedUser: true,
      showOnlyTeachers: true
    }
  },
  {
    path: '/profiel/gezinslid-toevoegen',
    name: 'AddFamilyMember',
    component: AddFamilyMember,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bevestiging/:oids',
    name: 'Confirmation',
    component: Confirmation,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/c/danssportvlaanderen',
    name: 'DanssportVlaanderen',
    component: DanssportVlaanderen
  },
  {
    path: '/c/danssportvlaanderen/lidkaart',
    name: 'DanssportVlaanderenCard',
    component: DanssportVlaanderenCard
  },
  {
    path: '/lidkaarten',
    name: 'MembershipCard',
    component: MembershipCard
  },
  {
    path: '/video',
    name: 'Video',
    component: Video,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/preview/course-form',
    name: 'CourseFormPreview',
    component: CourseFormPreview
  },
  {
    path: '/:club?',
    name: 'Club',
    alias: '/p/:club',
    component: Overview,
    meta: {
      requiresClub: true
    }
  },
  {
    path: '/:club?/formulieren',
    name: 'Forms',
    component: FormList,
    meta: {
      requiresClub: true,
      requiresAuth: false
    }
  },
  {
    path: '/:club?/formulieren/:form',
    name: 'FormDetail',
    component: FormDetail,
    meta: {
      requiresClub: true
    }
  },
  {
    path: '/:club?/cursussen',
    name: 'ClubCourse',
    component: Overview,
    meta: {
      requiresClub: true,
      type: 'course'
    }
  },
  {
    path: '/:club?/activiteiten',
    name: 'ClubActivity',
    component: Overview,
    meta: {
      requiresClub: true,
      type: 'activity'
    }
  },
  {
    path: '/:club?/ticketverkoop',
    name: 'ClubTicket',
    component: Overview,
    meta: {
      requiresClub: true,
      type: 'ticket'
    }
  },
  {
    path: '/:club/winkelmandje',
    name: 'Cart',
    component: Cart,
    meta: {
      requiresClub: true,
      requiresAuth: true,
      requiresSelectedUser: true
    }
  },
  {
    path: '/:club/uitnodigingen',
    name: 'Invite',
    component: Invite,
    meta: {
      requiresAuth: true,
      requiresSelectedUser: true,
      requiresClub: true
    }
  },
  {
    path: '/:club/webshop',
    name: 'Webshop',
    component: Webshop,
    meta: {
      requiresClub: true
    }
  },
  {
    path: '/:club/webshop/:product',
    name: 'WebshopDetail',
    component: WebshopDetail,
    meta: {
      requiresClub: true
    }
  },
  {
    path: '/:club/webshop/categorie/:category',
    name: 'WebshopCategory',
    component: WebshopCategory,
    meta: {
      requiresClub: true
    }
  },
  {
    path: '/:club/video/detail/:video',
    name: 'VideoDetail',
    component: VideoDetail,
    meta: {
      requiresClub: true,
      requiresAuth: true
    }
  },
  {
    path: '/:club/video/:course',
    name: 'VideoCourse',
    component: VideoCourse,
    meta: {
      requiresClub: true,
      requiresAuth: true
    }
  },
  {
    path: '/:club/tickets',
    name: 'Tickets',
    component: Ticket,
    meta: {
      requiresClub: true,
      requiresAuth: true
    }
  },
  {
    path: '/:club/beurtenkaart',
    name: 'Pass',
    component: Pass,
    meta: {
      requiresClub: true,
      requiresAuth: true
    }
  },
  {
    path: '/:club/beurtenkaart/herladen',
    name: 'PassReload',
    component: PassReload,
    meta: {
      requiresClub: true,
      requiresAuth: true
    }
  },
  {
    path: '/:club/beurtenkaart/logboek',
    name: 'PassLog',
    component: PassLog,
    meta: {
      requiresClub: true,
      requiresAuth: true
    }
  },
  {
    path: '/:club/beurtenkaart/reserveren',
    name: 'PassReserve',
    component: PassReserve,
    meta: {
      requiresClub: true,
      requiresAuth: true
    }
  },
  {
    path: '/:club/:course/:tariff?',
    name: 'Course',
    component: Course,
    meta: {
      requiresClub: true
    }
  }
]

const router = new VueRouter({
  mode: process.env.ROUTER_MODE || 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  // variables to deal with the embedded elements
  const appDiv = document.getElementById('ledenbeheer-embedded')
  const embeddedClub = appDiv?.getAttribute('data-club')
  const embeddedPage = appDiv?.getAttribute('data-page')
  const hideAside = appDiv?.getAttribute('data-hide-aside')

  if (to.name === 'DanssportVlaanderen' && to.query.hash) {
    // Logout
    await store.dispatch('user/logout')
  }
  const overwriteAuth = to?.query?.auth
  const { requiresClub, requiresAuth, requiresSelectedUser, defaultParentUser, showOnlyTeachers } = to.meta


  if (to.query.invite) {
    await store.dispatch('user/logout')
  }

  if (to.query.ccci) {
    const ccci = to.query.ccci
    const uid = ccci.split(';')[0]
    const [courseNid, tariffNid] = ccci.split(';')[1].split(':')

    await store.commit('setInviteData', {
      ccci,
      uid,
      courseNid,
      tariffNid
    })
  }

  if (!store.state.user.user || overwriteAuth) { // Not yet logged in
    const auth = overwriteAuth ?? read('auth')
    if (auth && auth !== 'false') { // User has token, try log them in
      try {
        const response = await api.post('validate_auth', {
          auth: auth,
          getPermissions: 1
        })
        if (overwriteAuth) {
          await store.commit('user/setAuth', auth)
        }
        if (response.data.dsv_members) {
          await store.commit('user/setDSVMembers', response.data.dsv_members)
        }
        if (response.data.federations) {
          await store.commit('user/setFederations', response.data.federations)
        }
        if (response.data.adminURL) {
          await store.commit('user/setAdminURL', response.data.adminURL)
        }
        if (response.data.user) {
          await store.commit('user/setUser', response.data.user)
        } else if (requiresAuth) {
          remove('auth')
          return next({ name: 'Login', query: { redirect: to.path } })
        }
      } catch (err) { // Invalid auth, if route requires auth, go to login first
        if (requiresAuth) {
          remove('auth')
          return next({ name: 'Login', query: { redirect: to.path } })
        }
      }
    } else if (requiresAuth) { // User does not have token, go to login
      return next({ name: 'Login', query: { redirect: to.path } })
    }
  }

  if (!store.state.user.selectedUser && store.getters['user/user']) { // Route requires auth, but no user is selected
    if (localStorage.getItem('selectedUser')) { // Has stored selectedUser, set it again
      const selectedUserData = JSON.parse(localStorage.getItem('selectedUser'))
      if (selectedUserData.timestamp > Date.now() - 1000 * 60 * 10 && selectedUserData.loggedInUid === store.getters['user/user'].uid) {
        await store.commit('user/setSelectedUser', parseInt(selectedUserData.uid))
        localStorage.setItem('selectedUser', JSON.stringify({
          loggedInUid: store.getters['user/user'].uid,
          ...selectedUserData,
          ...{
            timestamp: Date.now()
          }
        }))
      } else {
        localStorage.removeItem('selectedUser')
      }
    }
  }

  if (!store.state.club.club) {
    if (to.params.club || to.query.club || embeddedClub) {
      try {
        const response = await api.get('get_club_by_url', {
          url: to.params.club || to.query.club || embeddedClub
        })
        await store.dispatch('club/setClub', response.data.result)
        if (to.params.club !== response.data.result.url) {
          to.params.club = response.data.result.url // Probably nid passed, redirect to correct
        }

        const courseNid = to.hash.match(/#([0-9]+)/)?.[1]
        if (courseNid) {
          return next({ name: 'Course', params: { club: to.params.club, course: courseNid } })
        }
        const productNid = to.hash.match(/#webshop-([\d]+)/)?.[1]
        if (productNid) {
          return next({ name: 'WebshopDetail', params: { club: to.params.club, product: productNid } })
        }
        if (to.hash.match(/#webshop/)) {
          return next({ name: 'Webshop', params: { club: to.params.club } })
        }
        if (embeddedClub) {
          await store.commit('setIsEmbedded', true)
          if (hideAside === 'true') {
            await store.commit('setHideAside', true)
          }
          return next({ name: embeddedPage, params: { club: response.data.result.url } })
        }
        return next(to)
      } catch (err) {
        if (requiresClub) {
          return next({ name: 'Home', query: { redirect: to.path, requiresAuth, requiresClub } })
        }
      }
    } else if (requiresClub) {
      if (to.query.redirect) {
        return next({ name: 'Home', query: { redirect: to.query.redirect, requiresAuth, requiresClub } })
      } else {
        return next({ name: 'Home', query: { redirect: to.path, requiresAuth, requiresClub } })
      }
    }
  }

  if ((store.getters['user/user'] && requiresSelectedUser && !store.getters['user/selectedUser']) || to.query.uid) {
    if (to.query.uid) {
      await store.commit('user/setSelectedUser', to.query.uid)
    } else if (defaultParentUser) {
      // await store.commit('user/setSelectedUser', store.getters['user/user'].uid)
    } else {
      return next({ name: 'Home', query: { redirect: to.matched[0].path, requiresAuth, requiresClub, showOnlyTeachers } })
    }
  }

  // Set cart data
  await store.dispatch('cart/restoreCart')

  next()
})

export default router
