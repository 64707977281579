
<template>
  <FormGroup :title="label" :titleSuffix="labelSuffix" :help="help" :required="required" :slim="noFormGroup">
    <label>
        {{$t('Zoek je adres')}}
      </label>
      <input
        ref="autocompleteAddress"
        name="autocomplete-Address"
        :required="isAutocompleteRequired"
        onfocus="this.value=''"
      />
      <Grid>
        <Button v-if="!addressNotFound && !localValue.street && !localValue.city" type="button" warning fill @click="addressNotFound = true" class="not-found-button">Adres niet gevonden?</Button>
      </Grid>
    <Grid v-if="localValue.street || localValue.city || addressNotFound" class="address" :columns="{ tablet: 4 }">
      <Column :span="2">
        <Input
          :value="localValue.street"
          v-model="localValue.street"
          :label="$t('Straat')"
          :id="`${id}-street`"
          :required="isRequired"
          :v="isRequired && v?.street"
          @input="emitAddress"
        />
      </Column>
      <Column>
        <Input
          v-model="localValue.streetNumber"
          :label="$t('Nummer')"
          :id="`${id}-streetNumber`"
          type="text"
          :placeholder="$t('Nummer')"
          :v="isRequired && v?.streetNumber"
          :required="isRequired && !localValue.street"
          @input="emitAddress"
        />
      </Column>
      <Column>
        <Input
          v-model="localValue.bus"
          :label="$t('Bus')"
          :id="`${id}-bus`"
          type="text"
          :placeholder="$t('Bus')"
          @input="emitAddress"
        />
      </Column>
      <Column>
        <Input
          v-model="localValue.postal"
          :label="$t('Postcode')"
          :placeholder="$t('Postcode')"
          :v="isRequired && v?.postal"
          :id="`${id}-postal`"
          :required="isRequired"
          @input="emitAddress"
        />
      </Column>
      <Column :span="2">
        <Input
          v-model="localValue.city"
          :label="$t('Stad')"
          :placeholder="$t('Stad')"
          :required="isRequired"
          :id="`${id}-city`"
          :v="isRequired && v?.city"
          @input="emitAddress"
        />
      </Column>
      <Column>
        <Input
          v-model="localValue.country"
          label="Land"
          placeholder="Land"
          :id="`${id}-country`"
          type="text"
          :v="isRequired && v?.country"
          @input="emitAddress"
          :required="isRequired"
        />
      </Column>
    </Grid>
  </FormGroup>
</template>
<script setup>

import Column from "@/components/Core/Column.vue";
import Grid from "@/components/Core/Grid.vue";
import FormGroup from "@/components/Core/FormGroup.vue";
import Input from "@/components/Core/Input.vue";
import Button from "@/components/Core/Button.vue";
import {ref, onMounted, reactive, computed, onBeforeMount} from 'vue'
import { required } from 'vuelidate/lib/validators'

const addressNotFound = ref(false)
let autocomplete = ref(false)
const randomId = ref(false)
const autocompleteAddress = ref(false)

let localValue = reactive({
  street: '',
  streetNumber: '',
  bus: '',
  city: '',
  postal: '',
  country: ''
})

const { value, label,labelSuffix, isRequired, help, id } = defineProps({
  value: {
    type: [Object],
    default: () => ({
      street: '',
      streetNumber: '',
      bus: '',
      city: '',
      postal: '',
      country: ''
    })
  },
  label: {
    type: String
  },
  labelSuffix: {
    type: String
  },
  isRequired: {
    type: Boolean
  },
  help: {
    type: String
  },
  id: {
    type: String
  },
  v: Object,
  noFormGroup: Boolean
})
const emit = defineEmits(['input'])
// Conditional validations
const validations = computed(() => {
  if (isRequired) {
    return {
      localValue: {
        street: { required },
        streetNumber: { required },
        postal: { required },
        city: { required },
        country: { required }
      }
    }
  }
  return {} // No validations when not required
})

const isAutocompleteRequired = computed(() => {
  return isRequired && !localValue.street && !localValue.city && !addressNotFound;
})

onBeforeMount(() => {
  randomId.value = Math.floor(Math.random() * 100)
})
// load Google Maps
onMounted(async () => {
  await initMap()
  if ( value && value.street) {
    localValue.street = value.street
    localValue.streetNumber = value.streetNumber
    localValue.bus = value.bus
    localValue.city = value.city
    localValue.postal = value.postal
    localValue.country = value.country
  }
})

const initMap = async () => {
  await google.maps.importLibrary('places')
  autocomplete.value = new google.maps.places.Autocomplete(autocompleteAddress.value, {
    componentRestrictions: { country: ["be", "nl", "fr"] },
    fields: ["address_components"],
    types: ["address"]
  })
  autocomplete.value.addListener("place_changed", fillInAddress)
}
const fillInAddress = () => {
  const place = autocomplete.value.getPlace()
  if (!place.address_components) return
  localValue.street = ''
  localValue.streetNumber = ''
  localValue.bus = ''
  localValue.postal = ''
  localValue.city = ''
  localValue.country = ''
  for (const component of place.address_components) {
    if (component.types.includes('street_number')) {
        const numberAndBus = component.long_name.split(' ')
        localValue.streetNumber = numberAndBus[0]
        localValue.bus = numberAndBus[1]
      } else if (component.types.includes('route')) {
        localValue.street = component.long_name
      } else if (component.types.includes('postal_code')) {
        localValue.postal = component.long_name
      } else if (component.types.includes('sublocality')||component.types.includes('locality')) {
        localValue.city = component.long_name
      } else if (component.types.includes('country')) {
        localValue.country = component.short_name
      }
  }
  emitAddress()
}

const emitAddress = () => {
  emit('input', {
    street: localValue.street,
    streetNumber: localValue.streetNumber,
    bus: localValue.bus,
    city: localValue.city,
    postal: localValue.postal,
    country: localValue.country
  })
}

</script>

<style scoped>
.not-found-button {
  margin-left: auto;
  margin-top: 0.5rem;
}
.address {
  margin-top: 1rem;
}
</style>
<style>
.pac-container {
  z-index: 1000000000000;
}
</style>
