<template>
  <form class="registration-form" @submit.prevent="submit">
    <FormGroup v-if="allowHideEmpty && hasEmptyFields">
      <Checkbox :label="$t('Toon lege velden')" v-model="showHidden" />
    </FormGroup>
    <FormGroup>
      <Dropdown primary v-if="filtersForDropdown.length" :items="filtersForDropdown"  :placeholder="{ label: $t('Algemene vragen'), key: false }" :value="0" @change="value => specificCourse = value" />
    </FormGroup>

    <Section v-if="Object.values(newsletterData).length">
      <h3>{{ (custom && custom.newsletter && custom.newsletter.label) || 'Nieuwsbrief' }}</h3>
      <FormHelp v-if="custom && custom.newsletter && custom.newsletter.help">{{ custom.newsletter.help }}</FormHelp>
      <div v-for="(emailData, index) in newsletterData" :key="index">
        <Checkbox :checked="emailData.status === 'subscribed'" @input="status => emailData.status = (status ? 'subscribed' : 'unsubscribed')">{{ emailData.email }}</Checkbox>
      </div>
    </Section>
    <Section v-if="Object.values(newsletterData).length">
      <h3> Communicatie </h3>
      <div v-for="(emailData, index) in newsletterData" :key="index">
        <Checkbox :checked="emailData.communicationStatus === 'subscribed'" @input="communicationStatus => emailData.communicationStatus = (communicationStatus ? 'subscribed' : 'unsubscribed')">{{ emailData.email }}</Checkbox>
      </div>
    </Section>

    <Section v-for="(sectionData, k) in filteredValue" :key="`section_${k}`">
      <div v-if="hasShownFields(sectionData.fields)">
        <h3>{{ sectionData.section }}</h3>
        <div v-for="(field, l) in sectionData.fields" :key="`field_${l}`">
          <Field v-if="showField(field)" :field="field" v-model="field.answer" ref="field" :v="$v.fields[`${field.qid}_${field.nid}`].answer" :specific-inline="specificInline" @input="$emit('sync', field)" />
        </div>
      </div>
    </Section>
    <FormGroup v-if="agreement" :v="$v.agreementData">
      <Checkbox v-model="agreementData" :v="$v.agreementData">
        Ik ga akkoord met <a :href="`https://api.ledenbeheer.be/?__branch=ledenbeheer&q=view_club_conditions&club=${club.nid}`" target="_blank">de privacyverklaring en de voorwaarden van de club</a>.
      </Checkbox>
    </FormGroup>
    <div style="text-align: right;" v-if="!noButton">
      <Button v-if="localValue.length || agreement" fill :success="!isStep" :primary="isStep" :icon="isStep ? 'arrow-right' : 'check'" :loading="$store.getters.isLoading">{{ $t(isStep ? 'Volgende' : 'Opslaan') }}</Button>
    </div>
  </form>
</template>

<script>
import Vue from 'vue'
import Section from './Section'
import Field from './Field'
import Button from '../Core/Button'
import Dropdown from '../Core/Dropdown'
import FormGroup from '../Core/FormGroup'
import Checkbox from '../Core/Checkbox'
import { required, email } from 'vuelidate/lib/validators'
import { isFieldFilled } from '../../utils'
import { isPhone } from '../../utils/validators'
import FormHelp from '../Core/FormHelp'
export default {
  name: 'RegistrationForm',
  components: {
    FormHelp,
    Checkbox,
    FormGroup,
    Dropdown,
    Button,
    Field,
    Section
  },
  props: {
    value: [Array, Boolean],
    filters: Object,
    allowHideEmpty: Boolean,
    isStep: Boolean,
    noButton: Boolean,
    user: Object,
    agreement: Boolean,
    specificInline: Boolean,
    newsletter: Object,
    custom: Object,
    clubNid: Number,
    hideNationalityField: Boolean,
    hideAddressField: Boolean
  },
  data () {
    return {
      specificCourse: false,
      showHidden: false,
      localValue: [],
      agreementData: false,
      renderComponent: true,
      newsletterData: {}
    }
  },
  mounted () {
    this.localValue = [...this.value]
    this.newsletterData = this.newsletter || []
  },
  computed: {
    club () {
      return this.$store.getters['club/club']
    },
    fields () {
      const fields = {}
      for (const section of this.localValue) {
        for (const field of section.fields) {
          fields[`${field.qid}_${field.nid}`] = field
        }
      }
      return fields
    },
    filteredValue () {
      return this.localValue
    },
    hasEmptyFields () {
      for (const k in this.fields) {
        const field = this.fields[k]
        if ((!field.answer || field.answer.length === 0) && !field.required) return true
      }
      return false
    },
    selectedUser () {
      return this.$store.getters['user/selectedUser']
    },
    filtersForDropdown () {
      const filters = []
      for (const section in this.filters) {
        const items = this.filters[section].map(course => {
          return {
            key: course[0],
            value: course[1]
          }
        })
        filters.push({
          value: section,
          items
        })
      }
      return filters
    }
  },
  validations () {
    const validations = {
      fields: {}
    }
    if (this.agreement) {
      validations.agreementData = {
        required,
        hasAccepted: val => val === true
      }
    }
    for (const k in this.fields) {
      const field = this.fields[k]

      let customValidators = {}
      if (parseInt(field.required) || field.required === true) {
        if (field.type === 'address' && this.hideAddressField) continue
        customValidators = {
          ...customValidators,
          required
        }
        if (field.type === 'address' && !this.hideAddressField) {
          customValidators = {
            ...customValidators,
            street: {
              required
            },
            streetNumber: {
              required
            },
            postal: {
              required
            },
            city: {
              required
            },
            country: {
              required
            }
          }
        }
        if (field.type === 'checkbox') {
          customValidators = {
            ...customValidators,
            hasAccepted: val => val === true || parseInt(val) === 1 || val === 'true'
          }
        }
      }
      if (field.type === 'address') {
        customValidators = {
          ...customValidators
        }
      }
      if (field.type === 'phone') {
        customValidators = {
          ...customValidators,
          isPhone
        }
      }
      if (field.type === 'email') {
        customValidators = {
          ...customValidators,
          email
        }
      }
      validations.fields[k] = {
        answer: customValidators
      }
    }
    return validations
  },
  methods: {
    refresh () {
      this.localValue = [...this.value]
      if (!Array.isArray(this.$refs.field)) return
      for (const field of this.$refs.field) {
        field.refresh()
      }
    },
    showField (field) {
      if (!this.showHidden && this.allowHideEmpty && !isFieldFilled(field)) {
        if (field.required) {
          return true
        }
        return false
      }
      if (this.specificInline) {
        if (this.hideNationalityField && field.default_key === 'nationality') return false
        if (this.hideAddressField && field.default_key === 'address') return false
        return true
      }

      return (parseInt(field.nid) === 0 && this.specificCourse === false) || (parseInt(field.nid) === parseInt(this.specificCourse))
    },
    hasShownFields (fields) {
      return fields.filter(field => this.showField(field)).length > 0
    },
    submit () {
      this.$v.$touch()
      if (this.$v.$error) {
        if (!this.showHidden) {
          this.showHidden = true
        }

        // Which fields have errors
        const generalErrors = []
        const specificErrors = []
        for (const k in this.fields) {
          const field = this.fields[k]
          const $v = this.$v.fields[k]
          if ($v.$error) {
            if (parseInt(field.nid) === 0) {
              generalErrors.push(k)
            } else {
              specificErrors.push(field.nid)
            }
          }
        }
        if (generalErrors.length === 0 && specificErrors.length > 0) {
          this.specificCourse = specificErrors[0]
        }
        // Scroll to first item with error
        Vue.nextTick(() => {
          const hasErrorElement = document.querySelector('.has-error')
          if (hasErrorElement) {
            hasErrorElement.scrollIntoView({
              behavior: 'smooth'
            })
          }
        })
        this.$store.commit('notice/addNotices', {
          title: 'Onjuiste velden',
          message: 'Sommige velden zijn niet juist ingevuld',
          type: 'error'
        })
        return
      }

      const newsletter = {}
      for (const newsletterItem of this.newsletterData) {
        newsletter[newsletterItem.email] = newsletterItem.status
      }
      const communication = {}
      for (const newsletterItem of this.newsletterData) {
        communication[newsletterItem.email] = newsletterItem.communicationStatus
      }
      const communicationStatus = { [this.clubNid]: communication }

      this.$emit('submit', {
        qids: this.getFormData(true),
        newsletter,
        communicationStatus,
        club: this.clubNid
      })
    },
    getFormData (withoutUid) {
      const formData = {}
      for (const sectionData of this.localValue) {
        for (const field of sectionData.fields) {
          if (withoutUid) {
            formData[field.qid + '_' + field.nid] = field.answer
          } else {
            formData[field.qid + '_' + (this.user ? this.user.uid : this.selectedUser.uid) + '-' + field.nid] = field.answer
          }
        }
      }
      return formData
    },
    hasError () {
      this.$v.$touch()
      return this.$v.$error
    }
  }
}
</script>

<style>
.registration-form {
  margin-bottom: 1rem;
}
</style>
