<template>
  <i class="icon" :class="className" @click.stop="handleClick"></i>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    type: {
      default: 'fal',
      type: String
    },
    name: {
      type: String,
      required: true
    },
    animation: {
      type: [String, Boolean]
    },
    size: {
      type: [Number, String],
      default: 1
    }
  },
  computed: {
    className () {
      const classes = [this.type, 'fa-' + this.name]
      if (this.animation) {
        classes.push('fa-' + this.animation)
      }
      if (this.size) {
        classes.push(`fa-${this.size}x`)
      }
      if (this.$listeners.click) {
        classes.push('clickable')
      }
      return classes
    }
  },
  methods: {
    handleClick () {
      this.$emit('click')
    }
  }
}
</script>

<style>
.icon.clickable {
  cursor: pointer;
}
</style>
