<template>
  <div class="combined-input" :class="{ 'no-wrap': noWrap, 'input-group': $slots.prepend || $slots.append }">
    <div v-if="$slots.prepend" class="prepend mr-0" :class="{ 'input-group-addon': $slots.prepend && $slots.prepend[0].text }">
      <slot  name="prepend" />
    </div>
    <slot />
    <div v-if="$slots.append" class="append ml-0" :class="{ 'input-group-addon': $slots.append && $slots.append[0].text }">
      <slot name="append" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CombinedInput',
  props: {
    noWrap: Boolean
  }
}
</script>

<style lang="scss">
.combined-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &.no-wrap {
    flex-wrap: nowrap;
  }

  > *:not(:last-child) {
    margin-right: 0.3rem;
  }
}
.prepend, .append {
  min-height: 2.49rem;
  display: flex;
  min-width: auto;

  > * {
    min-height: inherit;
  }
}
</style>
