<template>
  <div class="card" :class="classNames" @click="handleClick" :id="`card-${_uid}`">
    <div v-if="blur && $slots.blur" class="blur-cta">
      <Card>
        <slot name="blur" />
      </Card>
    </div>
    <slot name="label" />
    <slot name="image" />
    <Radio v-if="radio" ref="input" :checked="checked" :value="value" :locked="locked" :disabled="disabled" @input="selectCard" />
    <Checkbox v-if="checkbox" ref="input" :checked="checked" :value="value" :disabled="disabled" @input="selectCard" />
    <main>
      <Button icon="trash" danger v-if="$listeners.delete && !removeDelete" onlyIcon style="float: right;" @click="$emit('delete')" />
      <h1 v-if="title">{{ title }}</h1>
      <slot />
      <div class="details" v-show="localOpen">
        <slot name="details" />
      </div>
      <slot v-if="$slots.footer" name="footer" />
      <footer v-else-if="$slots.details" @click="localOpen = !localOpen">
        <Icon :name="localOpen ? 'chevron-up' : 'chevron-down'" />
      </footer>
    </main>
    <slot name="aside" />
  </div>
</template>

<script>
import Vue from 'vue'
import Icon from './Icon'
import Radio from './Radio'
import Checkbox from './Checkbox'
import Button from './Button'
export default {
  name: 'Card',
  components: {
    Button,
    Checkbox,
    Radio,
    Icon
  },
  props: {
    title: String,
    fill: Boolean,
    bordered: Boolean,
    value: [Object, Boolean, String, Number],
    radio: [Boolean, Object],
    checkbox: Boolean,
    disabled: Boolean,
    primary: Boolean,
    success: Boolean,
    checked: [Array, Boolean, Object, String],
    shadow: Boolean,
    column: Boolean,
    locked: Boolean,
    open: Boolean,
    v: Object,
    removeDelete: Boolean,
    loading: Boolean,
    blur: Boolean
  },
  model: {
    prop: 'checked',
    event: 'input'
  },
  data () {
    return {
      localOpen: false
    }
  },
  mounted () {
    this.localOpen = this.open
    const unwatchOpenWatcher = this.$watch('open', (newVal) => {
      if (newVal) {
        this.localOpen = newVal
        unwatchOpenWatcher()
      }
    })
  },
  computed: {
    classNames () {
      const classes = []
      if (this.fill) {
        classes.push('fill')
      }
      if (this.$slots.details) {
        classes.push('has-footer')
      }
      if (this.bordered) {
        classes.push('bordered')
      }
      if (this.radio || this.checkbox || this.$listeners.click) {
        classes.push('clickable')
      }
      if (this.isChecked) {
        classes.push('selected')
      }
      if (this.primary) {
        classes.push('primary')
      }
      if (this.success) {
        classes.push('success')
      }
      if (this.disabled) {
        classes.push('disabled')
      }
      if (this.$slots.image) {
        classes.push('has-image')
      }
      if (this.shadow) {
        classes.push('has-shadow')
      }
      if (this.column) {
        classes.push('column')
      }
      if (this.v && this.v.$error) {
        classes.push('error')
      }
      if (this.loading) {
        classes.push('loading')
      }
      if (this.blur) {
        classes.push('blur')
      }
      return classes
    },
    isChecked () {
      if (Array.isArray(this.checked)) {
        return this.checked.includes(this.value)
      }
      return typeof this.checked === 'boolean' ? this.checked : this.checked === this.value
    }
  },
  methods: {
    selectCard () {
      this.$emit('input', this.value)
    },
    handleClick (e) {
      if (this.$refs.input && typeof this.$refs.input.handleChange === 'function' && !this.disabled) {
        this.$refs.input.handleChange()
      } else {
        this.$emit('click')
      }
    },
    close () {
      this.localOpen = false
      Vue.nextTick(() => {
        setTimeout(() => {
          document.getElementById(`card-${this._uid}`).scrollIntoView({
            behavior: 'smooth'
          })
        }, 250)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes card-loading {
  0% {
    left: 0;
    transform: scaleX(1);
  }
  50% {
    left: 50%;
    transform: scaleX(1.5) translateX(-50%);
  }
  100% {
    left: 100%;
    transform: scaleX(1) translateX(-100%);
  }
}

.blur-cta {
  position: absolute;
  z-index: 1;
  filter: none !important;
  top: 1.5rem;
  left: 1.5rem;
  width: calc(100% - 3rem);
  pointer-events: all !important;
}

.card {
  background: #fff;
  border: 1px solid var(--gray-light);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  margin-bottom: 2rem;
  position: relative;
  width: 100%;
  transition: 150ms ease-in-out;

  main {
    width: 100%;
  }

  &.column {
    flex-direction: column;
  }

  &.has-image{
    padding: 0;

    > main {
      padding: 1.5rem;
    }
  }

  &.has-shadow {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
  }

  &.primary {
    border-color: var(--primary-color);
    color: var(--primary-color);

    &.clickable:hover {
      background: var(--primary-color);
      color: #fff;
    }
  }

  &.success {
    border-color: var(--success-color);
    color: var(--success-color);

    &.clickable:hover {
      background: var(--success-color);
      color: #fff;
    }
  }

  &.fill {
    background: var(--gray);

    &.primary {
      background: var(--primary-color);
      color: #fff;
    }
  }

  &.has-footer {
    padding-bottom: calc(1.5rem + 22.8px);
  }

  &.bordered {
    border-color: var(--gray-darker);
  }

  &.clickable {
    cursor: pointer;
  }

  &.selected {
    border-color: var(--primary-color);
  }

  &.blur > * {
    filter: blur(5px);
    pointer-events: none;
  }

  &.disabled {
    //opacity: 0.6;
    cursor: not-allowed;
  }

  &.loading::after {
    content: '';
    width: 50px;
    height: 2px;
    background: var(--primary-color);
    position: absolute;
    bottom: 0;
    left: 0;
    animation-name: card-loading;
    animation-duration: 2.5s;
    animation-timing-function: cubic-bezier(0.45, 0.05, 0.55, 0.95);
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }
}
footer {
  background: var(--gray);
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  padding: 0.2rem 0;
}
.details {
  margin-top: 1rem;
}
.card > .pill {
  position: absolute;
  top: 0;
  left: 1.5rem;
  transform: translateY(-50%);
}
@media screen and (min-width: 768px) {
  .card {
    flex-direction: row;
  }
}
</style>
